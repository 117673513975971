import React, {useState} from "react";
import { FileIcon, defaultStyles } from 'react-file-icon';
import {VideoPlayer} from "../widgets/AssetModal";
import {useViewMode} from "./ViewMenu";
import {isMobile} from "device-detect";
import {Badge, Tooltip} from "antd";

export default ({asset, assetSize, thumbType, fileIconSize, hovering, forceGrid=false})=> {
    const {viewMode, colorLabel} = useViewMode()

    if(asset.type == 'Video' && thumbType == 'permalink') thumbType = 'permalink_image'

    const src = asset[`${thumbType}_url`] || asset['small_url'] || asset[`grid_url`] // fallback for video types

    const size = src || !fileIconSize ? assetSize : fileIconSize

    const isGrid = forceGrid || viewMode === 'grid'
    const isPosts = viewMode === 'posts'

    const style = {width: size, objectFit: 'scale-down', borderRadius: isGrid ? 10 : 0, overflow: 'hidden',
        backgroundImage: asset.ext == 'png' && `url(${ImageUrls.checkerImage})`, backgroundSize: 15}

    if(colorLabel) {
        asset.custom_meta_values?.filter(cmv => cmv.custom_meta_field_id === colorLabel && cmv.color_label?.length)?.map(cmv => {
            style.border = `8px solid ${cmv.color_label}`;
            style.backgroundColor = cmv.color_label;
        })
    }

    let aspectRatio
    if(isGrid) {
        style.height = size

    } else if(isPosts) {
        if(asset.type === 'Video') {
            // Currently video previews are locked at 4:3 aspect ratio:
            style.height = (isMobile() ? window.innerWidth : Config.defaultPostWidth) / 1.333;

        } else {
            aspectRatio = asset.width / asset.height
            style.height = (isMobile() ? window.innerWidth : Config.defaultPostWidth) / aspectRatio
        }
    }

    const extStyles = defaultStyles[asset.ext]

    const [loadError, setLoadError] = useState()
    const onImgLoadError = ()=> {
        setLoadError(true)
    }

    const { autoPlay } = useViewMode()

    if((((autoPlay && hovering) || !isGrid) && asset.type === 'Video' && asset.small_url)) {
        return (
            <div style={{height: !isGrid ? size / 1.333 : 'auto'}}>
                <VideoPlayer
                    style={{backgroundColor:'#fff !important'}}
                    width={size}
                    height={isGrid && size}
                    muted={isGrid}
                    loop={isGrid}
                    autoplay={isGrid}
                    preload={isGrid && 'auto'}
                    inactivityTimeout={0}
                    asset={asset}
                    key={asset.id}
                    controls={!isGrid}
                    sources={ [ {type:'video/mp4', src: asset.small_url} ] }
                    className={'video-js vjs-default-skin vjs-static-controls'}
                />

            </div>
        )
    }

    if(!loadError && src) {
        const isPortrait = asset.height > asset.width;
        return (
            <div style={{...style, backgroundImage: 'none', display: 'flex', justifyContent: 'center', alignItems: 'center'}} className={'asset-grid-image-wrapper'}>
                <img
                    src={src}
                    draggable={false}
                    style={{
                        width: isPortrait ? 'auto' : '100%',
                        height: isPortrait ? '100%' : 'auto',
                        objectFit: 'scale-down',
                        backgroundImage: asset.has_alpha && `url(${ImageUrls.checkerImage})`,
                        backgroundSize: 15
                    }}
                    onError={onImgLoadError}
                    alt={asset.alt_text || asset.description}
                />
            </div>
        )
    }

    if (!src && asset.content_preview?.length)
        return (
            <div style={{
                ...style,
                position: 'relative', display: 'flex', justifyContent: 'center', overflow:'hidden', backgroundColor: 'white', height: size - 5}}
                className={'asset-file-icon-wrapper'}
            >
                <small>
                    <pre style={{whiteSpace: 'pre-wrap', textOverflow: 'ellipsis', width: size, padding:'.5em'}} className={'content-preview'}>
                        {asset.content_preview}
                    </pre>
                </small>
            </div>
        )

    return !isGrid ? (
        <div style={{...style, position:'relative', backgroundColor: 'lightgrey'}}>

        </div>
    ) : (
        <div style={{...style, position:'relative', display: 'flex', justifyContent: 'center', padding:1}} className={'asset-file-icon-wrapper'}>
            <FileIcon extension={asset.ext} {...extStyles} radius={2}/>
        </div>
    )
}