import React, {useEffect, useRef, useState} from "react";
import {Formik} from 'formik';
import {Form, FormItem, Input, SubmitButton, Radio, Checkbox, Select, Switch, DatePicker} from "formik-antd";
import * as Yup from "yup";

import {
    Badge,
    Button,
    Collapse, Descriptions,
    Divider,
    Drawer,
    message,
    Modal,
    Popconfirm,
    Space,
    Tag,
    Tooltip
} from 'antd';

import HelpPopover from "../HelpPopover";

import {
    SaveOutlined,
    PoweroffOutlined, UserAddOutlined, CheckOutlined, CloseCircleOutlined
} from '@ant-design/icons';

import Draggable from 'react-draggable';

import api from "../api";

import {useNavigate} from "react-router-dom-v5-compat";
import ExclamationCircleOutlined from "@ant-design/icons/lib/icons/ExclamationCircleOutlined";
import TimeAgo from "../helpers/TimeAgo";
import User from "../helpers/User";

import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "device-detect";
import { useTranslation } from "react-i18next";
import VerticalSpace from "../helpers/VerticalSpace";

export default ({onSave, visible, onClose, membershipRequest}) => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    if(!membershipRequest.invite_attributes) {
        membershipRequest.invite_attributes = {role_level: 'general'}
    }

    const apiPath = `/api/membership_requests/${membershipRequest.id}`

    let modal = null;
    const onCloseDirty =(dirty)=> {
        // FIXME: calling onClose directly has no effect
        if(modal) return;

        modal = Modal.confirm({
            title: 'Cancel edit?',
            icon: <ExclamationCircleOutlined />,
            content: dirty ? 'You have unsaved changes.' : '',
            onOk: onClose,
            onCancel: () => { modal = null },
            cancelText: 'No',
            okText: 'Yes'
        })
    }

    const [width, setWidth] = useState(isMobile() ? '100%' : '70%');

    const onDrag = (e) => {
        const w = (1 - (e.screenX / window.innerWidth)) * 100;
        const els = document.getElementsByClassName('ant-drawer-content-wrapper');
        [].forEach.call(els, (el) => {
            el.style.width = `${w}%`;
        });
    }

    const onDragStop = (e) => {
        const w = (1 - (e.screenX / window.innerWidth)) * 100;
        console.log('onDragStop', e)
        setWidth(`${w}%`);
    }

    const afterOpenChange = (open)=> {
        // autoFocusInput.current.focus();
    }

    const [userGroups, setUserGroups] = useState([]);
    const [roleLevels, setRoleLevels] = useState([]);

    useEffect(()=> {
        if(!visible) return;
        // TODO: use axios all for parallel:
        api('/api/user_groups?assignable=true').then(res => setUserGroups(res.data))
        api('/api/invites/available_role_levels').then(res => setRoleLevels(res.data))
    }, [visible])

    const radioStyle = { display: 'block' };

    return (
        (<Formik
            initialValues={membershipRequest}
            onSubmit={(values, actions) => {
                if(!values.response) values.response = 'accept'

                if(values.response === 'reject') values.invite_attributes = null;

                api({
                    method:'patch',
                    url: apiPath,
                    data: { membership_request: values }
                }).then((res)=>{
                    actions.setSubmitting(false)
                    if(res.data.errors) {
                        message.warning(`Error: ${formatError(res.data.errors)}`)
                        return
                    }
                    actions.resetForm()

                    message.success(`${res.data.email} ${values.response}ed!`)
                    onSave && onSave(res.data);

                }).catch((error)=>{
                    console.error(error)
                    message.error(`Error: ${JSON.stringify(error.response?.data)}`)
                    actions.setSubmitting(false)
                })
            }}
        >
            {({setFieldValue, values, submitForm, dirty, isSubmitting}) => {

                // Dev helper to auto open the pertinent panel:
                const defaultActiveKey = '';

                const reject = ()=> {
                    setFieldValue('response', 'reject')
                    submitForm()
                }

                return (
                    (<div>
                        <Drawer
                            title={
                                <>
                                    <UserAddOutlined/>
                                    {t('membership-request','Membership Request')}
                                </>
                            }
                            footer={
                                <div style={{ textAlign: 'right', }} >
                                    <Space direction={'horizontal'}>
                                        <Popconfirm title={t('reject-membership-request','Reject Membership Request?')} onConfirm={reject}>
                                            <Button danger icon={<CloseCircleOutlined/>}>{t('reject','Reject')}</Button>
                                        </Popconfirm>

                                        <SubmitButton onClick={submitForm} type={'primary'} htmlType={'submit'}>
                                            <CheckOutlined/>
                                            {t('approve','Approve')}
                                        </SubmitButton>
                                    </Space>
                                </div>
                            }
                            width={width}
                            placement={'right'}
                            open={visible}
                            onClose={() => onCloseDirty(dirty)}
                            // destroyOnClose={true}
                            // maskClosable={false}
                            afterOpenChange={afterOpenChange}
                        >
                            <Form layout='vertical'>
                                <Descriptions bordered size='small' column={1}>
                                    <Descriptions.Item label={t('first-name','First Name')}>{membershipRequest.first_name}</Descriptions.Item>
                                    <Descriptions.Item label={t('last-name','Last Name')}>{membershipRequest.last_name}</Descriptions.Item>
                                    <Descriptions.Item label={t('email','Email')}>{membershipRequest.email}</Descriptions.Item>
                                    <Descriptions.Item label={t('note','Note')}>{membershipRequest.note}</Descriptions.Item>
                                    <Descriptions.Item label={t('status','Status')}><Tag>{membershipRequest.aasm_state}</Tag></Descriptions.Item>
                                    {membershipRequest.processed_at && (
                                        <Descriptions.Item label={t('processed','Processed')}>
                                            <TimeAgo date={membershipRequest.processed_at}/> by <User user={membershipRequest.processed_by}/>
                                        </Descriptions.Item>
                                    )}
                                </Descriptions>

                                <Divider/>

                                <FormItem label={t('note','Note')} name="invite_attributes.note">
                                    <Input.TextArea name="invite_attributes.note" placeholder={t('this-note-will-be-included-in-the-email','This note will be included in the email.')} />
                                </FormItem>

                                <FormItem label={t('role','Role')} name="role_level" required>
                                    <Radio.Group name={'invite_attributes.role_level'} required>
                                        <Radio value='general' style={radioStyle}>
                                            {t('general','General')}
                                            <HelpPopover text={t('general-user','General User')}/>
                                        </Radio>

                                        {roleLevels.indexOf('global_tagger') != -1 && (
                                            <Radio value='global_tagger' style={radioStyle}>
                                                {t('global-tagger','Global Tagger')}
                                                <HelpPopover text={t('tag-all-contents-in-library','Tag all contents in Library')}/>
                                            </Radio>
                                        )}

                                        {roleLevels.indexOf('global_library') != -1 && (
                                            <Radio value='global_library' style={radioStyle}>
                                                {t('global-library-manager','Global Library Manager')}
                                                <HelpPopover text={t('manage-the-library-contents-and-access','Manage the Library contents and access')}/>
                                            </Radio>
                                        )}

                                        {roleLevels.indexOf('global_content') != -1 && (
                                            <Radio value='global_content' style={radioStyle}>
                                                {t('global-content-manager','Global Content Manager')}
                                                <HelpPopover text={t('manage-all-content-and-access-move-uploads-to-the-library','Manage all content and access, move uploads to the Library')}/>
                                            </Radio>
                                        )}

                                        {roleLevels.indexOf('admin') != -1 && (
                                            <Radio value='admin' style={radioStyle}>
                                                {t('admin','Admin')}
                                                <HelpPopover text={t('organization-wide-admin','Organization wide Admin')}/>
                                            </Radio>
                                        )}
                                    </Radio.Group>
                                </FormItem>

                                {!!userGroups.length && (
                                    <FormItem label={t('user-groups','User Groups')} name="invite_attributes.user_group_ids">
                                        <Checkbox.Group name='invite_attributes.user_group_ids' >
                                            <VerticalSpace>
                                                {userGroups.map(group => (
                                                    <div key={group.id}>
                                                        <Checkbox key={group.id} value={group.id}>{group.name}</Checkbox>
                                                    </div>
                                                ))}
                                            </VerticalSpace>
                                        </Checkbox.Group>
                                    </FormItem>
                                )}
                            </Form>
                            <Draggable axis="x" disabled={!visible} onDrag={onDrag} onStop={onDragStop} defaultPosition={{x:0}}>
                                <div style={
                                    {
                                        top:0, position: 'fixed', height: '100vh', width:'3px', cursor:'col-resize',
                                        zIndex: 9999, right: width, display: (visible ? '' : 'none')
                                    }
                                }/>
                            </Draggable>
                        </Drawer>
                    </div>)
                );
            }}
        </Formik>)
    );
};

