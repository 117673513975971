import React, {useEffect, useRef, useState} from "react";
import {Formik} from 'formik';
import {Form, FormItem, Input, SubmitButton, Radio, Checkbox, Select, Switch, DatePicker} from "formik-antd";
import * as Yup from "yup";

import {Badge, Collapse, Divider, Drawer, message, Modal, Space, Tag, Tooltip} from 'antd';

import HelpPopover from "../HelpPopover";

import {
    SaveOutlined,
    PoweroffOutlined
} from '@ant-design/icons';

import Draggable from 'react-draggable';

import api from "../api";

import {useParams} from "react-router-dom";
import ExclamationCircleOutlined from "@ant-design/icons/lib/icons/ExclamationCircleOutlined";

import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "device-detect";
import AssetGroupChooser from "./AssetGroupChooser";
import { useTranslation } from "react-i18next";
import VerticalSpace from "../helpers/VerticalSpace";

export default ({id, onSave, visible, onClose}) => {
    const { t } = useTranslation();

    if(!id) {
        const { id } = useParams();
    }

    const [invite, setInvite] = useState({role_level: 'general'});
    const apiPath = '/api/invites';

    // Only load when Drawer opened:
    useEffect(() => {
        if(!visible) return;

        const path = id ? `${id}/edit` : 'new';

        api.get(`${apiPath}/${path}`).then((res) => {
            setInvite(res.data);
        });
    }, [id, visible]);

    const autoFocusInput = useRef(null);

    const modal = useRef();
    const onCloseDirty =(dirty)=> {
        if(modal.current) return;

        if(dirty) {
            modal.current = Modal.confirm({
                title: 'Cancel edit?',
                icon: <ExclamationCircleOutlined />,
                content: dirty ? 'You have unsaved changes.' : '',
                onOk: () => {
                    modal.current = null
                    onClose()
                },
                onCancel: () => { modal.current = null },
                cancelText: 'No',
                okText: 'Yes'
            })
        } else {
            onClose()
        }
    }

    const [width, setWidth] = useState(isMobile() ? '100%' : '70%');

    const onDrag = (e) => {
        const w = (1 - (e.screenX / window.innerWidth)) * 100;
        const els = document.getElementsByClassName('ant-drawer-content-wrapper');
        [].forEach.call(els, (el) => {
            el.style.width = `${w}%`;
        });
    }

    const onDragStop = (e) => {
        const w = (1 - (e.screenX / window.innerWidth)) * 100;
        console.log('onDragStop', e)
        setWidth(`${w}%`);
    }

    const afterOpenChange = (open)=> {
        autoFocusInput.current.focus();
    }

    let lastEmailCheck = null;

    const [userGroups, setUserGroups] = useState([]);
    const [roleLevels, setRoleLevels] = useState([]);

    useEffect(()=> {
        if(!visible) return;
        // TODO: use axios all for parallel:
        api('/api/user_groups?assignable=true').then(res => setUserGroups(res.data))
        api('/api/invites/available_role_levels').then(res => setRoleLevels(res.data))
    }, [id, visible])

    const radioStyle = { display: 'block' };

    return (
        (<Formik
            initialValues={invite}
            onSubmit={(values, actions) => {
                api({
                    method:'post',
                    url: '/api/invites',
                    data: { invite: values }
                }).then((res)=>{
                    actions.resetForm()
                    actions.setSubmitting(false)
                    message.success(`${res.data.invites.length} invited!`)
                    onSave && onSave(res.data);

                }).catch((error)=>{
                    console.error(error)
                    message.error(`Error: ${JSON.stringify(error.response.data)}`)
                    actions.setSubmitting(false)
                })
            }}
            // validationSchema={
            //     Yup.object({
            //         email: Yup.string()
            //             .email().required('Required')
            //             .test('checkUniqueEmail','email already exists', function(value){
            //                 if(value == lastEmailCheck) return true;
            //                 return new Promise((resolve, reject) => {
            //                     api.post('/api/invites/check_email', {email: value}).then((res)=>{
            //                         if(res.data.ok) lastEmailCheck = value;
            //                         resolve(res.data.ok)
            //                     }).catch(() => resolve(false));
            //                 })
            //             }),
            //     })
            // }
        >
            {({setFieldValue, values, submitForm, dirty, isSubmitting}) => {

                // Dev helper to auto open the pertinent panel:
                const defaultActiveKey = '';

                return (
                    (<div>
                        <Drawer
                            title={
                                <>
                                    {id ? t('edit','Edit') : t('create','Create')} {t('invite','Invite')}

                                    <HelpPopover text={
                                        <div>
                                            Create a Rights Package to...
                                        </div>
                                    }/>
                                </>
                            }
                            footer={
                                <div style={{ textAlign: 'right', }} >
                                    <Space direction={'horizontal'}>
                                        <SubmitButton onClick={submitForm} type={'primary'} htmlType={'submit'}>
                                            <SaveOutlined/>
                                            {id ? t('update','Update') : t('create','Create')} {t('invite','Invite')}
                                        </SubmitButton>
                                    </Space>
                                </div>
                            }
                            width={width}
                            placement={'right'}
                            open={visible}
                            onClose={() => onCloseDirty(dirty)}
                            // destroyOnClose={true}
                            // maskClosable={false}
                            afterOpenChange={afterOpenChange}
                        >
                            <Form layout='vertical'>
                                <FormItem label={t('email','Email')} name='email' required extra={t('comma-or-semicolon-delimited','Comma or semicolon delimited.')}>
                                    <Input
                                        autoFocus required ref={autoFocusInput}
                                        name="email" placeholder="Email" />
                                </FormItem>

                                <FormItem label={t('note','Note')} name="note">
                                    <Input.TextArea name="note" placeholder={t('this-note-will-be-included-in-the-email','This note will be included in the email.')} />
                                </FormItem>

                                <Divider/>

                                <FormItem label={t('role','Role')} name="role_level">
                                    <Radio.Group name='role_level' value={values.role_level}>
                                        <Radio value='general' style={radioStyle}>
                                            {t('general','General')}
                                            <HelpPopover text={t('general-user','General User')}/>
                                        </Radio>

                                        {roleLevels.indexOf('global_tagger') != -1 && (
                                            <Radio value='global_tagger' style={radioStyle}>
                                                {t('global-tagger','Global Tagger')}
                                                <HelpPopover text={t('tag-all-contents-in-library','Tag all contents in Library')}/>
                                            </Radio>
                                        )}

                                        {roleLevels.indexOf('global_library') != -1 && (
                                            <Radio value='global_library' style={radioStyle}>
                                                {t('global-library-manager','Global Library Manager')}
                                                <HelpPopover text={t('manage-the-library-contents-and-access','Manage the Library contents and access')}/>
                                            </Radio>
                                        )}

                                        {roleLevels.indexOf('global_content') != -1 && (
                                            <Radio value='global_content' style={radioStyle}>
                                                {t('global-content-manager','Global Content Manager')}
                                                <HelpPopover text={t('manage-all-content-and-access-move-uploads-to-the-library','Manage all content and access, move uploads to the Library')}/>
                                            </Radio>
                                        )}

                                        {roleLevels.indexOf('admin') != -1 && (
                                            <Radio value='admin' style={radioStyle}>
                                                {t('admin','Admin')}
                                                <HelpPopover text={t('organization-wide-admin','Organization wide Admin')}/>
                                            </Radio>
                                        )}
                                    </Radio.Group>
                                </FormItem>

                                {userGroups && (
                                    <FormItem label={t('user-groups','User Groups')} name="user_group_ids">
                                        <Checkbox.Group name='user_group_ids' >
                                            {userGroups.map(group => (
                                                <VerticalSpace>
                                                    <div key={group.id}>
                                                        <Checkbox key={group.id} value={group.id}>{group.name}</Checkbox>
                                                    </div>
                                                </VerticalSpace>
                                            ))}
                                        </Checkbox.Group>
                                    </FormItem>
                                )}

                                <Divider/>

                                <FormItem label={t('destination-collection','Destination Collection')} name={'asset_group_id'} extra={t('optional-user-will-be-taken-here-after-sign-up','(Optional) User will be taken here after sign-up.')}>
                                    <AssetGroupChooser type={'collections'} fieldName={'asset_group_id'} organizerSelection/>
                                </FormItem>
                            </Form>
                            <Draggable axis="x" disabled={!visible} onDrag={onDrag} onStop={onDragStop} defaultPosition={{x:0}}>
                                <div style={
                                    {
                                        top:0, position: 'fixed', height: '100vh', width:'3px', cursor:'col-resize',
                                        zIndex: 9999, right: width, display: (visible ? '' : 'none')
                                    }
                                }/>
                            </Draggable>
                        </Drawer>
                    </div>)
                );
            }}
        </Formik>)
    );
};

